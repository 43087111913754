import { MapCenter } from "../store/report/reportTypes";

export const minimapCenter: MapCenter = {
    latitude: 22.494344461527874,
    longitude: -74.102611221886,
    zoom: 5.75,
};

export const parseWKTBBox = (wkt: string) => {
    const matches = wkt.match(/(-?\d+(\.\d+)?),?(-?\d+(\.\d+)?)/g);
    if (!matches) {
        throw new Error(`Could not parse WKT: ${wkt}`);
    }

    let north = -90
    let south = 90
    let east = -180;
    let west = 180;

    for (let i = 0; i < matches.length; i += 2) {
        const long = parseFloat(matches[i]);
        const lat = parseFloat(matches[i + 1]);

        if (long > east) {
            east = long;
        }
        if (long < west) {
            west = long;
        }
        if (lat > north) {
            north = lat;
        }
        if (lat < south) {
            south = lat;
        }
    }


    return {
        north,
        south,
        east,
        west,
        sw_lat: south,
        sw_lon: west,
        ne_lat: north,
        ne_lon: east,
        bbox: [east, south, west, north] as [number, number, number, number],
    };
};

export const constructWKTBBox = (
    n: string | number,
    e: string | number,
    s: string | number,
    w: string | number,
) => {
    return `POLYGON((${w} ${n}, ${e} ${n}, ${e} ${s}, ${w} ${s}, ${w} ${n}))`;
};
