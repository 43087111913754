import React, { FC, ReactElement, useEffect } from "react";
import { Layer, Marker, Source } from "react-map-gl";
import { useSelector } from "react-redux";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { CirclePaint } from "mapbox-gl";
import { Feature, Point } from "@turf/helpers";
import CreateDonutChart from "utils/Clusters";
import { generateHash } from "utils/Maths";
import DonutChart from "utils/Clusters";

interface InsightsMarkersProps {
    currentZoom: number;
}

export const InsightsMarkers: FC<InsightsMarkersProps> = ({
    currentZoom,
}): ReactElement | null => {
    let assessmentType = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").assessmentType,
    );
    let insightsStyles = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsStyles,
    );
    const clusterFeatures = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").clusterFeatures,
    );

    if (!insightsStyles) return null;

    const set = new Set();

    return (
        <>
            {clusterFeatures
                .filter((feature) => {
                    const clusterId = feature.properties?.cluster_id;
                    return !set.has(clusterId) && set.add(clusterId);
                })
                .map((feature) => {
                    return (
                        <Marker
                            key={feature.properties.cluster_id}
                            latitude={
                                (feature as Feature<Point>).geometry
                                    .coordinates[1]
                            }
                            longitude={
                                (feature as Feature<Point>).geometry
                                    .coordinates[0]
                            }
                            anchor="center"
                        >
                            <DonutChart
                                cluster={JSON.parse(feature.properties!.counts)}
                                insightsStyle={insightsStyles[assessmentType]}
                                opacity={12 - currentZoom}
                            />
                        </Marker>
                    );
                })}
        </>
    );
};

export const InsightsLayers: FC = (): ReactElement | null => {
    let insightsVisible = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsVisible,
    );
    let locationLabels = useSelector(
        (state) => getStoreAtNamespaceKey(state, "report").locationLabels,
    );
    let assessmentType = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").assessmentType,
    );
    let insightsStyles = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsStyles,
    );
    const selectedRevisionId = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").selectedRevisionId,
    );

    const showClusters = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").showClusters,
    );

    const contractIds = useSelector(
        (state) =>
            getStoreAtNamespaceKey(state, "insights").insightsFilters
                .contractIds,
    );

    const insightsCursor = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsCursor,
    );

    const dashboardView = useSelector(
        (state) => getStoreAtNamespaceKey(state, "report").dashboardView,
    );

    const [pointsUrl, setPointsUrl] = React.useState(
        `${
            import.meta.env.VITE_GIM_ROOT
        }/tiles/${selectedRevisionId}/{z}/{x}/{y}.mvt${
            insightsCursor ? "?cursor=" + insightsCursor : ""
        }`,
    );

    useEffect(() => {
        if (dashboardView === "report") {
            setPointsUrl(
                `${
                    import.meta.env.VITE_GIM_ROOT
                }/tiles/${selectedRevisionId}/{z}/{x}/{y}.mvt${
                    insightsCursor ? "?cursor=" + insightsCursor : ""
                }`,
            );
        }
    }, [dashboardView, insightsCursor, selectedRevisionId]);

    if (
        !insightsStyles ||
        !insightsVisible ||
        !selectedRevisionId ||
        !assessmentType
    ) {
        return null;
    }

    const filter =
        contractIds && contractIds.length > 0
            ? {
                  filter: [
                      "in",
                      ["to-string", ["get", "MIS_ContractID"]],
                      ["literal", contractIds],
                  ],
              }
            : {};

    return (
        <>
            {showClusters && (
                <Source
                    id={"insights_clusters"}
                    type={"vector"}
                    tiles={[
                        `${
                            import.meta.env.VITE_GIM_ROOT
                        }/tiles/${selectedRevisionId}/{z}/{x}/{y}.mvt?is_clustered=true&is_claims=${
                            assessmentType === "claims"
                        }`,
                    ]}
                    maxzoom={9}
                    //@ts-ignore: Not in react-map-gl types
                    fillzoom={9}
                >
                    <Layer
                        id={"insights_clusters"}
                        source={"insights_clusters"}
                        source-layer="layer"
                        type="circle"
                        paint={{
                            "circle-radius": 0,
                        }}
                    />
                </Source>
            )}

            <Source
                key={`${showClusters}`}
                id={"insights_points"}
                type={"vector"}
                tiles={[pointsUrl]}
                minzoom={showClusters ? 10 : 0}
            >
                {insightsStyles &&
                    insightsStyles[assessmentType]?.style?.paint && (
                        <Layer
                            id={"Insights Assessment"}
                            source={"insights_points"}
                            source-layer="layer"
                            type="circle"
                            {...filter}
                            paint={{
                                ...(insightsStyles[assessmentType].style
                                    .paint as CirclePaint),
                                "circle-radius": [
                                    "interpolate",
                                    ["linear"],
                                    ["zoom"],
                                    0,
                                    2,
                                    11,
                                    2,
                                    12,
                                    3,
                                ],
                                "circle-opacity": [
                                    "interpolate",
                                    ["exponential", 3],
                                    ["zoom"],
                                    11,
                                    showClusters ? 0 : 1,
                                    12,
                                    1,
                                ],
                                "circle-stroke-opacity": [
                                    "interpolate",
                                    ["exponential", 3],
                                    ["zoom"],
                                    11,
                                    showClusters ? 0 : 1,
                                    12,
                                    1,
                                ],

                                "circle-stroke-width": [
                                    "interpolate",
                                    ["linear"],
                                    ["zoom"],
                                    0,
                                    1,
                                    11,
                                    1,
                                    12,
                                    1,
                                ],
                            }}
                            beforeId={locationLabels ? "road-label" : ""}
                        />
                    )}
            </Source>
        </>
    );
};
