import React, { useCallback, useEffect, useMemo } from "react";
import startCase from "lodash/startCase";
import { connect, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import axios from "axios";

import { RootState } from "../../../../../../../../store/store";
import {
    LayersConfig,
    MenuConfig,
    SetHighlightedLayerPayload,
} from "../../../../../../../../store/report/reportTypes";
import DataTree from "../../../../../DataTree/DataTree";
import classes from "./InfoTab.module.css";
import { getStoreAtNamespaceKey } from "../../../../../../../../store/storeSelectors";
import ScrollableText from "../../../../../../../_Library/ScrollableText/ScrollableText";
import { formatValue } from "store/insights/utils";
import { Flex, Loader } from "@mantine/core";
import { useOktaAuth } from "@okta/okta-react";

const InfoTab: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(false);

    const [featurePropertiesList, setFeaturePropertiesList] = React.useState<
        any[]
    >([]);

    const highlightedLayer = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").highlightedLayer,
    );

    const clickedFeatureProperties = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").clickedFeatureProperties,
    );

    const selectedRevisionId = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedRevisionId,
    );

    const { authState } = useOktaAuth();

    useEffect(() => {
        if (
            highlightedLayer &&
            clickedFeatureProperties?.[highlightedLayer.layerName]
        ) {
            const { layerName } = highlightedLayer;
            const propertiesList = clickedFeatureProperties?.[layerName];

            if (layerName !== "Insights Assessment") {
                setFeaturePropertiesList(clickedFeatureProperties[layerName]);
            }
            const getInsightsData = async () => {
                const insightsPromises = propertiesList.map((properties) =>
                    axios.get(
                        `${
                            import.meta.env.VITE_GIM_ROOT
                        }/points/${selectedRevisionId}/${properties["id"]}`,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + authState?.accessToken?.accessToken,
                            },
                        },
                    ),
                );

                try {
                    const responses = await Promise.all(insightsPromises);
                    const insightsResults = responses.map((res) => res.data);

                    setFeaturePropertiesList(insightsResults);
                } catch (error) {
                    console.error("Error fetching insights:", error);
                    // Handle the error appropriately
                } finally {
                    setIsLoading(false);
                }
            };
            getInsightsData();
            setIsLoading(true);
        }
    }, [authState?.accessToken, clickedFeatureProperties, highlightedLayer, selectedRevisionId]);

    const renderRows = useCallback((propertyList: { [key: string]: any }) => {
        return Object.keys(propertyList)
            .filter(
                (propertyName) =>
                    propertyName !== "feature_id" &&
                    !propertyName.includes("MIS_"),
            )
            .map((propertyName) => {
                let result: string | number = propertyList[propertyName];

                return (
                    <tr key={propertyName}>
                        <td>
                            <ScrollableText text={startCase(propertyName)} />
                        </td>
                        <td className={classes.DataCol}>
                            <DataTree
                                data={formatValue(result, propertyName)}
                            />
                        </td>
                    </tr>
                );
            });
    }, []);

    if (isLoading) {
        return (
            <Flex justify={"center"} p={"1rem"}>
                <Loader />
            </Flex>
        );
    } else {
        return (
            <div className={classes.InfoTab}>
                {highlightedLayer !== null ? (
                    <div className={classes.SelectedFeaturesContainer}>
                        <p className={classes.LayerTitle}>
                            {highlightedLayer.layerName}
                        </p>
                        <p className={classes.FeatureCount}>
                            {featurePropertiesList.length} feature
                            {featurePropertiesList.length !== 1 && "s"} shown
                        </p>
                        {featurePropertiesList.map(
                            (
                                propertyList: { [key: string]: any },
                                index: number,
                            ) => (
                                <table
                                    key={index}
                                    className={classes.InfoTable}
                                >
                                    <colgroup>
                                        <col />
                                        <col className={classes.DataCol} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderRows(propertyList)}</tbody>
                                </table>
                            ),
                        )}
                    </div>
                ) : (
                    <div className={classes.SelectedFeaturesContainer}>
                        <p>No Features Selected</p>
                    </div>
                )}
            </div>
        );
    }
};

export default InfoTab;
