import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import classes from "./Insights.module.css";
import cx from "classnames";
import { Skeleton } from "@mantine/core";
import BasePage from "components/Pages/BasePage/BasePage";
import StatBar from "components/_Library/StatBar/StatBar";
import { PortfolioItem } from "store/user/userTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Icon from "@mdi/react";
import { mdiHelpCircleOutline, mdiInformationOutline } from "@mdi/js";
import Modal from "components/_Library/Modal/Modal";
import { InsightsTable } from "components/Pages/Report/DashboardComponents/Insights/InsightsTable/InsightsTable";
import ReactTooltip from "react-tooltip";
import { buildExportName } from "components/Pages/Report/DashboardComponents/Insights/InsightsTable/utils";
import { MRT_Row } from "mantine-react-table";
import { setContractIdFilter } from "store/insights/insightsActions";
import numeral from "numeral";
import { InsightsBannerWrapper } from "./InsightsBannerWrapper/InsightsBannerWrapper";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useUserMeAccessQuery } from "crud/me_access";
import { filterGeojsonByProperties, geoJSONFromPapaparse } from "utils/Geojson";
import { getStoreAtNamespaceKey } from "store/storeSelectors";

type PolicyInsightsProps = {
    eventId: string;
    selectedPortfolio: PortfolioItem;
};

export const PolicyInsights: FC<PolicyInsightsProps> = (
    props: PolicyInsightsProps,
) => {
    const storeData = useSelector(
        (state: RootState) => state.insights?.policyData! || [],
    );
    const dispatch = useDispatch();

    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const isFetchingInsights: boolean = useSelector(
        (state: RootState) => state?.insights!.isFetchingInsights,
    );
    const [data, setData] = useState<{
        data: Record<string, string | number>[];
        stats: {
            total_estimated_exposure: number;
            total_exposed_policies: number;
        };
    }>(storeData);
    // Parse unique columns from data, memoized
    const uniqueColumns = useMemo(() => {
        const columns = new Set<string>();
        if (data.data) {
            data?.data.forEach((row) => {
                Object.keys(row).forEach((key) => columns.add(key));
            });
        }
        return Array.from(columns);
    }, [data]);
    const event = useSelector((state: RootState) =>
        state.service!.eventData.find((event) => event.id === props.eventId),
    );
    const [grossExposureModal, setGrossExposureModal] =
        useState<boolean>(false);
    const insightsData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsData,
    );
    const policyData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").policyData,
    );

    const locationData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").locationData,
    );

    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const renderBarCharts = () => {
        return (
            <div className={classes.InsightsBarCharts}>
                <div className={classes.IndividualBar}>
                    <StatBar
                        canFilterInsights={false}
                        countLabel={"Exposure Layer Assessment"}
                        suffix={"Locations"}
                        barKeyOrientation={"portrait"}
                        applyToAssessment="exposure"
                        data={
                            !insightsData?.insights
                                ? []
                                : insightsData?.insights?.exposure
                        }
                        tooltip={
                            "Chart presenting the breakdown of location count split by damage assessment for the Exposure Layer Assessment."
                        }
                        onClick={(label) => {
                            trackUserEventWithCurrentEvent({
                                name: "bar_chart_segment_clicked",
                                payload: {
                                    source: "policy",
                                    type: "exposure",
                                    assessment: label,
                                },
                            });
                        }}
                    />
                </div>
                <div className={classes.IndividualBar}>
                    <StatBar
                        countLabel={"Claims Layer Assessment"}
                        suffix={"Locations"}
                        applyToAssessment="claims"
                        canFilterInsights={false}
                        barKeyOrientation={"portrait"}
                        data={
                            !insightsData?.insights.claims
                                ? []
                                : insightsData?.insights?.claims
                        }
                        tooltip={
                            "Chart presenting the breakdown of location count split by damage assessment for the Claims Layer Assessments."
                        }
                        onClick={(label) => {
                            trackUserEventWithCurrentEvent({
                                name: "bar_chart_segment_clicked",
                                payload: {
                                    source: "policy",
                                    type: "claims",
                                    assessment: label,
                                },
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    const getColumnNames = useCallback(async () => {
        if (!props.selectedPortfolio?.id) return;
        setFetchLoading(true);
        setData({
            data: [],
            stats: {
                total_estimated_exposure: 0,
                total_exposed_policies: 0,
            },
        });

        if (policyData) {
            setData(policyData);
        }

        setFetchLoading(false);
    }, [props.selectedPortfolio.id, policyData]);

    useEffect(() => {
        getColumnNames();
    }, [getColumnNames]);

    const getTotalEstimatedExposure = () => {
        return (
            <span className={classes.Capitalize}>
                ${numeral(data.stats.total_estimated_exposure).format("0.00a")}
            </span>
        );
    };

    const getTotalUniqueExposedPolicies = () => {
        return data.stats.total_exposed_policies;
    };

    const renderExposure = () => {
        if (data.data.length) {
            return (
                <div className={classes.ExposureContainer}>
                    <div className={classes.GrossExposureContainer}>
                        <div className={classes.TitleContainer}>
                            <p className={classes.Title}>
                                Total Estimated Exposure
                            </p>
                            <div
                                className={cx(
                                    classes.InfoIcon,
                                    classes.Pointer,
                                )}
                                onClick={() => setGrossExposureModal(true)}
                            >
                                <Icon size={1} path={mdiInformationOutline} />
                            </div>
                        </div>
                        <div className={classes.InnerContainer}>
                            <div className={classes.BarContainer}>
                                <div className={classes.Statistic}>
                                    {getTotalEstimatedExposure()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.GrossExposureContainer}>
                        <div className={classes.TitleContainer}>
                            <p className={classes.Title}>
                                Total Exposed Policies
                            </p>
                            <div
                                className={classes.InfoIcon}
                                data-tip={
                                    "The number of unique policies that have a total estimated exposure greater than zero."
                                }
                                data-for={"statTitleTooltip"}
                            >
                                <Icon size={1} path={mdiHelpCircleOutline} />
                                <ReactTooltip
                                    id={"statTitleTooltip"}
                                    place={"left"}
                                    effect={"float"}
                                />
                            </div>
                        </div>
                        <div className={classes.InnerContainer}>
                            <div className={classes.BarContainer}>
                                <div className={classes.Statistic}>
                                    {getTotalUniqueExposedPolicies()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.GrossExposureContainer}>
                    <Skeleton width={"3rem"} height={"1.5rem"} />
                    <div className={classes.InnerContainer}>
                        <div className={classes.BarContainer}>
                            <Skeleton width={"20rem"} height={"3rem"} />
                        </div>
                    </div>
                </div>
            );
        }
    };

    const { data: accessData } = useUserMeAccessQuery();

    const displayData = () => {
        return data?.data?.length &&
            accessData?.has_policy_access &&
            !isFetchingInsights &&
            !fetchLoading ? (
            <div className={cx(classes.Container)}>
                <div className={classes.InsightsContent}>
                    <div className={classes.InsightsTopBar}>
                        {renderBarCharts()}
                        {renderExposure()}
                    </div>

                    <div className={classes.InnerContainer}>
                        <InsightsTable
                            loading={fetchLoading}
                            columns={Array.from(uniqueColumns)}
                            exportSuffix={
                                insightsData
                                    ? `${buildExportName(
                                          insightsData!.description,
                                          event?.name || "",
                                      )}_Policy`
                                    : ""
                            }
                            beforeShowOnMap={(rows: MRT_Row[]) => {
                                let contractIds = rows.map((row: MRT_Row) => {
                                    return (row.original as Record<string, any>)
                                        ?.MIS_ContractID;
                                });

                                dispatch(
                                    setContractIdFilter({
                                        contractIds: contractIds,
                                    }),
                                );

                                return {
                                    type: "FeatureCollection",
                                    features: [],
                                };
                            }}
                            allowShowOnMap
                            formatData
                        />
                    </div>
                </div>
            </div>
        ) : (
            <InsightsBannerWrapper
                loading={isFetchingInsights || fetchLoading}
                policy
            />
        );
    };

    return (
        <BasePage>
            {displayData()}
            {grossExposureModal && (
                <Modal closeModal={() => setGrossExposureModal(false)}>
                    <div>
                        <h2>Total Estimated Exposure</h2>
                        <p>
                            The Estimated Exposure calculation offers a
                            preliminary view of your potential exposure
                            resulting from this event. By incorporating your
                            policy parameters—such as limits, excess,
                            deductibles—and applying your share to the Total
                            Insured Value (TIV) of the impacted locations within
                            the policy, this tool facilitates an early,
                            high-level impact assessment on your policies.
                        </p>
                        <br />
                        <p>
                            <b>Please Note:</b>
                        </p>
                        <ul>
                            <li>
                                This calculation is designed to complement, not
                                replace, in-depth financial modelling. It
                                provides a snapshot based on available data,
                                helping you identify potential exposure areas
                                quickly.
                            </li>
                            <br />
                            <li>
                                While this tool provides valuable early
                                insights, decisions should not be based solely
                                on this calculation. Comprehensive financial
                                modelling, considering all variables and
                                scenarios, remains essential for an accurate
                                event response.
                            </li>
                        </ul>
                    </div>
                </Modal>
            )}
        </BasePage>
    );
};
