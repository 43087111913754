import { useState, useEffect, useMemo } from 'react';
import { 
    PaginationState, 
    ColumnSort, 
    ColumnFilter, 
    ColumnPinningState, 
    VisibilityState,
    ColumnFiltersState,
    SortingState,
    Updater
} from '@tanstack/react-table';
import { MRT_ColumnFilterFnsState, MRT_FilterFn, MRT_ColumnFiltersState } from 'mantine-react-table';
import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';
import { useSelector } from 'react-redux';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { RootState } from 'store/store';
import { getStoreAtNamespaceKey } from 'store/storeSelectors';
import { layerAssessmentColumnNames } from 'utils/MantineTable';
import { InsightsResponse, PaginatedResponse } from 'crud/hooks/insights';

interface UseTableStateProps {
    source: "insights" | "map";
    type: "location" | "policy"
    initialAssessmentFilters?: {
        exposure: string[];
        claims: string[];
    };
    initialPinnedColumns?: string[];
}

// Type for tracked table state
type TrackedTableState = {
    sorting: ColumnSort[];
    globalFilter: string;
    columnFilters: MRT_ColumnFiltersState;
    columnFilterFns: MRT_ColumnFilterFnsState | undefined;
    columnPinning: ColumnPinningState;
    columnVisibility: VisibilityState;
};

interface TableState extends TrackedTableState {
    resetColumnFilters: () => void;
}

interface TableEventHandlers {
    onSortingChange: (updater: Updater<SortingState>) => void;
    onColumnFiltersChange: (updater: Updater<ColumnFiltersState>) => void;
    onColumnFilterFnsChange: (updater: Updater<MRT_ColumnFilterFnsState>) => void;
    onGlobalFilterChange: (updater: Updater<string | undefined>) => void;
    onColumnPinningChange: (updater: Updater<ColumnPinningState>) => void;
    onColumnVisibilityChange: (updater: Updater<VisibilityState>) => void;
    onPaginationChange: (updater: Updater<PaginationState>) => void;
}

const DEFAULT_FILTERS: TrackedTableState = {
    sorting: [
        {
            id: "ground_up_TIV_USD",
            desc: true
        }
    ],
    globalFilter: "",
    columnFilters: [
        {
            id: "Exposure Layer Assessment",
            value: []
        },
        {
            id: "Claims Layer Assessment",
            value: []
        }
    ],
    columnFilterFns: undefined,
    columnPinning: {
        left: [], 
        right: []
    },
    columnVisibility: {},
};

export const useTableStateWithTracking = ({
    source,
    type,
    initialAssessmentFilters = { exposure: [], claims: [] },
    initialPinnedColumns = ["estimated_exposure"],
}: UseTableStateProps) => {
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const [sorting, setSorting] = useState<ColumnSort[]>(DEFAULT_FILTERS.sorting);
    const [globalFilter, setGlobalFilter] = useState<string>(DEFAULT_FILTERS.globalFilter);
    const queryClient = useQueryClient();
    const selectedRevisionId = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "insights").selectedRevisionId
    )
    const data = queryClient.getQueryData(["insightsDetails", selectedRevisionId]) as InfiniteData<PaginatedResponse<InsightsResponse>>
    const columns = useMemo(() => {
        const keys = Object.keys(data?.pages?.length > 0 && data?.pages[0]?.data?.items[0] || {})
        return keys;
    }, [data])
    const defaultFilters: MRT_ColumnFiltersState= useMemo(() => (
        [
            {
                id: "Exposure Layer Assessment",
                value: initialAssessmentFilters.exposure.length
            ? initialAssessmentFilters.exposure
            : DEFAULT_FILTERS.columnFilters[0].value
            },
            {
                id: "Claims Layer Assessment",
                value: initialAssessmentFilters.claims.length
            ? initialAssessmentFilters.claims
            : DEFAULT_FILTERS.columnFilters[1].value
            }
        ]
    ), [initialAssessmentFilters.exposure, initialAssessmentFilters.claims])
    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>(defaultFilters);
    // Ensure all columns are set up in state as columns come in
    useEffect(() => {
        // When columns exist, ensure column filters are set
        // If they are already set, do not mutate
        if (columns.length) {
            const additionalColumns = columns.filter(
                (column) => !["Exposure Layer Assessment", "Claims Layer Assessment"].includes(column)
            ).map((column) => ({
                id: column,
                value: undefined,
            }))
            setColumnFilters((prev) => [...prev, ...additionalColumns]);
        }
    }, [columns]);

    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(DEFAULT_FILTERS.columnFilterFns!);
    // Ensure all column filter fns are set up in state as columns come in
    useEffect(() => {
        // When columns exist, ensure column filter fns are set
        // If they are already set, do not mutate
        // Only add contains to non layer assessment column names
        if (columns.length) {
            const columnFilterFnsFromFilters = columns.filter(
                    (col) => !layerAssessmentColumnNames.includes(col)
                )
                .reduce((acc, column) => ({
                ...acc,
                [column]: ("contains" as MRT_FilterFn)
            }), {})
            setColumnFilterFns((prev) => {
                return {...prev, ...columnFilterFnsFromFilters}
            });
        }
    }, [columns])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [], 
        right: initialPinnedColumns.length
            ? initialPinnedColumns
            : DEFAULT_FILTERS.columnPinning.right
    });
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(DEFAULT_FILTERS.columnVisibility);

    const getValueFromUpdater = (updater: Updater<any>, state: any): any => {
        if (typeof updater !== "function") {
            return updater;
        }
        const value = updater(state);
        return value;
    }

    const eventHandlers: Omit<TableEventHandlers, "onPaginationChange"> = {
        onSortingChange: (updater) => {
            const newValue: ColumnSort[] = getValueFromUpdater(updater, sorting);
            const newSorted = newValue.find((col) => !sorting.find((oldCol) => oldCol.id === col.id));
            setSorting(newValue);
            if (newSorted) {
                trackUserEventWithCurrentEvent({
                    name: "table_sorted",
                    payload: {
                        source,
                        type,
                        column: newSorted.id,
                        mode: newSorted.desc ? "desc" : "asc",
                    },
                });
            }
        },

        onColumnFiltersChange: (updater) => {
            let newValue: ColumnFiltersState = getValueFromUpdater(updater, columnFilters);
            setColumnFilters(newValue);
            if (newValue?.length) {
                trackUserEventWithCurrentEvent({
                    name: "table_column_filtered",
                    payload: {
                        source,
                        type,
                        column: newValue[0].id,
                    }
                });
            }
        },

        onColumnFilterFnsChange: (updater) => {
            const newValue: MRT_ColumnFilterFnsState = getValueFromUpdater(updater, columnFilterFns);
            setColumnFilterFns(newValue);
            if (Object.keys(newValue).length) {
                trackUserEventWithCurrentEvent({
                    name: "table_filter_mode_changed",
                    payload: {
                        source,
                        type,
                        column: Object.keys(newValue)[0],
                        mode: Object.values(newValue)[0],
                    }
                });
            }
        },

        onGlobalFilterChange: (updater) => {
            const newValue = getValueFromUpdater(updater, globalFilter);
            setGlobalFilter(newValue!);
            if (newValue) {
                trackUserEventWithCurrentEvent({
                    name: "table_searched",
                    payload: {
                        source,
                        type,
                    }
                });
            }
        },

        onColumnPinningChange: (updater) => {
            const newValue: ColumnPinningState = getValueFromUpdater(updater, columnPinning);
            const newLeft = newValue["left"]?.find((col) => !columnPinning["left"]?.find((oldCol) => oldCol === col));
            const newRight = newValue["right"]?.find((col) => !columnPinning["right"]?.find((oldCol) => oldCol === col));
            const oldLeft = columnPinning["left"]?.find((col) => !newValue["left"]?.find((oldCol) => oldCol === col));
            const oldRight = columnPinning["right"]?.find((col) => !newValue["right"]?.find((oldCol) => oldCol === col));
            
            if (oldLeft || oldRight) {
                setColumnPinning(newValue);
                trackUserEventWithCurrentEvent({
                    name: "table_column_unpin",
                    payload: {
                        source,
                        type,
                        column: (oldLeft || oldRight)!
                    }
                });
            }
            
            if (newLeft || newRight) {
                if (newLeft) {
                    setColumnPinning({
                        ...columnPinning,
                        left: [...columnPinning!.left!, newLeft]
                    })
                } else if (newRight) {  
                    setColumnPinning({
                        ...columnPinning,
                        right: [...columnPinning!.right!, newRight]
                    })
                }
                trackUserEventWithCurrentEvent({
                    name: "table_column_pin",
                    payload: {
                        source,
                        type,
                        column: (newLeft || newRight)!
                    }
                });
            }
        },

        onColumnVisibilityChange: (updater) => {
            const newValue: VisibilityState = getValueFromUpdater(updater, columnVisibility);
            setColumnVisibility(updater);
            if (Object.keys(newValue).length) {
                const col = Object.keys(newValue)[0];
                const value = newValue[col];
                trackUserEventWithCurrentEvent({
                    name: Boolean(value) ? "table_column_unhide" : "table_column_hide",
                    payload: {
                        source,
                        type,
                        column: col,
                    }
                });
            }
        },
    };

    const columnFiltersExisting = columnFilters.filter(({id, value}) => {
        if (layerAssessmentColumnNames.includes(id)) {
            return defaultFilters.find((df) => df.id === id)?.value !== value
        }
        return Boolean(value)
    })
    
    // This is used so that when query filters get updated, tanstack can trigger re-fetch
    const sortingIsNotDefault = JSON.stringify(sorting) !== JSON.stringify(DEFAULT_FILTERS.sorting);
    const queryParamsKey = (
        columnFiltersExisting?.length || sortingIsNotDefault || globalFilter
    ) ? [`?columnFilters=${JSON.stringify(columnFiltersExisting)}&globalFilter=${globalFilter}&sorting=${JSON.stringify(sorting)}`] : [];
    const state: TableState = {
        sorting,
        globalFilter,
        columnFilters,
        columnFilterFns,
        columnPinning,
        columnVisibility,
        resetColumnFilters: () => {
            setColumnFilters(DEFAULT_FILTERS.columnFilters);
            setGlobalFilter(DEFAULT_FILTERS.globalFilter);
        }
    };

    return { state, eventHandlers, queryParamsKey };
};