import React, {
    FC,
    useMemo,
    useRef,
    useCallback,
    useEffect,
    MutableRefObject,
    UIEvent,
    CSSProperties,
    Suspense,
    useState,
} from "react";
import classes from "../Insights.module.css";
import { useViewportSize } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
    MRT_Header,
    MRT_Row,
    MRT_TableInstance,
    MantineReactTable,
    MRT_GlobalFilterTextInput as MRT_GLOBAL_FILTER_TEXT_INPUT,
    MRT_ShowHideColumnsButton as MRT_SHOW_HIDE_COLUMNS_BUTTON,
    useMantineReactTable,
    MRT_ColumnDef,
} from "mantine-react-table";
import { Box, Flex, Loader, LoadingOverlay, Text } from "@mantine/core";
import Button from "../../../../../_Library/Button/Button";
import { getCssVar } from "utils/CSSHelpers";
import {
    mdiDotsVertical,
    mdiDownload,
    mdiFilter,
    mdiFilterRemove,
    mdiMenuDown,
    mdiMenuSwap,
    mdiMenuUp,
} from "@mdi/js";
import Icon from "@mdi/react";
import { setDashboardView } from "store/report/reportActions";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { extractColumnNames, allFilterModes } from "utils/MantineTable";
import {
    SetAssessmentFilters,
    setShowClusters,
} from "store/insights/insightsActions";
import ReactTooltip from "react-tooltip";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { PerilType } from "store/system/systemTypes";
// required to prevent tooltips persisting on the mantine table
import "./mantine-override.modules.css";
import { IconSearch } from "@tabler/icons-react";
import { useDownloadPermission } from "hooks/authorization/useDownloadPermission";
import { useTableStateWithTracking } from "./useTableStateWithTracking";
import { useApiQuery } from "hooks/useAPI";
import { QueryParams, useInsightsDetailsQuery } from "crud/hooks/insights";
import { useCurrentEvent } from "hooks/useCurrentEvent";
import * as _ from "lodash";
import { GeoJSONObject } from "@turf/helpers/dist/js/lib/geojson";
import { parseWKTBBox } from "utils/Coordinates";
import axios from "axios";

type InsightsTableProps = {
    loading: boolean;
    columns?: string[];
    // filtered_ or raw_ will be prepended to the name
    exportSuffix: string;
    viewportHeightPct?: number;
    tableContainerMaxHeight?: string;
    beforeShowOnMap: (rows: MRT_Row[]) => GeoJSONObject;
    allowShowOnMap: boolean;
    formatData?: boolean;
    eventId?: string;
};

const generateLoadingRows = (): MRT_ColumnDef<any>[] => {
    return Array.from({ length: 20 }, (_, i) => {
        return {
            accessorKey: `Loading ${i}`,
            header: `Column ${i + 1}`,
            Header: ({ column }: MRT_Header) => (
                <Box style={{ color: "gray" }} miw={"10vw"}>
                    {column.columnDef.header}
                </Box>
            ),
        };
    });
};

export const InsightsTable: FC<InsightsTableProps> = ({
    loading,
    exportSuffix,
    viewportHeightPct,
    tableContainerMaxHeight,
    beforeShowOnMap,
    allowShowOnMap = true,
    formatData = false,
    eventId,
}: InsightsTableProps) => {
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const viewport = useViewportSize();
    const currentEvent = useCurrentEvent();
    const viewportHeight = viewport.height * ((viewportHeightPct || 100) / 100);
    const dispatch = useDispatch();

    const modalRef = useSelector(
        (state) => getStoreAtNamespaceKey(state, "ref").modalRef,
    );
    const insightsCursor = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsCursor,
    );

    const peril = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedPeril,
    );
    const { disable: disableDownloads, loading: permissionLoading } =
        useDownloadPermission();

    const mapRef = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "ref").mapRef,
    );
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user!,
    );
    const selectedRevisionId = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedRevisionId,
    );
    const [isDownloading, setIsDownloading] = useState(false);

    // Use the custom hook for fetching data
    const isPolicy = exportSuffix.includes("Policy");
    const source = modalRef.current && !isPolicy ? "map" : "insights";
    const type = isPolicy ? "policy" : "location";
    const assessmentFilters = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").assessmentFilters,
    );

    const { data: exposureDamageClassifications } = useApiQuery<[string[]]>(
        `/events/assessments/damage_classifications/?peril_type=${peril}&assessment_type=exposure`,
        ["exposure_damage_classifications", peril!],
        {
            staleTime: Infinity,
            enabled: Boolean(peril),
        },
    );

    const { data: claimsDamageClassifications } = useApiQuery<[string[]]>(
        `/events/assessments/damage_classifications/?peril_type=${peril}&assessment_type=claims`,
        ["claims_damage_classifications", peril!],
        {
            staleTime: Infinity,
            enabled: Boolean(peril),
        },
    );

    // TODO:
    // const columnHeaders = useMemo(() => {
    //     return (data?.length && exposureDamageClassifications && claimsDamageClassifications)
    //         ? extractColumnNames(data, columns ?? [], formatData, exposureDamageClassifications[0] , claimsDamageClassifications[0]) //Always retrieve the first array, to ensure we use the latest version.
    //         : generateLoadingRows();
    // }, [data, columns, formatData, exposureDamageClassifications, claimsDamageClassifications]);

    const dashboardView = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").dashboardView,
    );
    const { state, eventHandlers, queryParamsKey } = useTableStateWithTracking({
        source,
        type,
        initialAssessmentFilters: assessmentFilters,
    });

    const sorting = useMemo(() => {
        if (state.globalFilter) {
            return {};
        } return {
            sorting: state.sorting
        }
    }, [state.globalFilter, state.sorting]);
    const query: QueryParams = {
        global_filter: state.globalFilter,
        ...sorting,
        columnFilters: state.columnFilters
            ?.map((filter) => ({
                column: filter.id,
                operator: _.get(state?.columnFilterFns, filter.id, "equals"),
                value: filter.value,
            }))
            .filter(({ column, value, operator}) => {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return Boolean(value)
            }),
        limit: 25,
    };
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
        useInsightsDetailsQuery(selectedRevisionId!, query, queryParamsKey, {
            enabled: Boolean(selectedRevisionId),
            staleTime: Infinity,
        });

    const columnHeaders: MutableRefObject<MRT_ColumnDef<any>[]> = useRef(
        generateLoadingRows(),
    );

    const showSkeletons = data === undefined || data?.pages?.length === 0;
    useEffect(() => {
        const hasData =
            data &&
            data?.pages?.length > 0 &&
            data?.pages[0]?.data?.items?.length > 0;
        if (hasData) {
            columnHeaders.current = extractColumnNames(
                data?.pages[0]?.data?.items,
                [],
                formatData,
            );
        }
    }, [data, formatData]);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const SCROLL_THRESHOLD = 0.85;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const scrollPosition = containerRefElement?.scrollTop;
                if (scrollPosition === 0) return;
                const totalHeight = containerRefElement?.scrollHeight;
                const visibleHeight = containerRefElement?.clientHeight;

                // Calculate how far through the content we've scrolled (as a percentage)
                const scrollPercentage =
                    (scrollPosition + visibleHeight) / totalHeight;
                if (
                    scrollPercentage > SCROLL_THRESHOLD &&
                    !isLoading &&
                    hasNextPage
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, hasNextPage, isLoading],
    );

    // Add effect to check if we need to load more data on mount
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);
    // Update data processing:
    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data.items) ?? [],
        [data],
    );
    const insightsData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsData,
    );
    const totalHeight = useMemo(
        () => calculateHeight(viewportHeight) || 400,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [viewportHeight, dashboardView],
    );

    function calculateHeight(viewPortHeight: number): number {
        // Value of rem changes based on the font size of the root element
        const oneRem = parseFloat(
            getComputedStyle(document.documentElement).fontSize,
        );
        const insightsTopBarX = 15 * oneRem;
        const navbarHeight = 4 * oneRem;
        const topBar = 7 & oneRem;
        const gap = oneRem;
        const topTableBar = 6.6 * oneRem;
        const bottomPadding = 3 * oneRem;
        const bottomBar = 4.4 * oneRem;
        const availableHeight =
            viewPortHeight -
            navbarHeight -
            insightsTopBarX -
            topBar -
            topTableBar -
            bottomPadding -
            bottomBar -
            gap -
            70;

        return availableHeight;
    }

    const renderTableActionBar = (table: MRT_TableInstance<any>) => {
        return (
            <Box>
                <Flex justify={"space-between"} style={{ padding: "0.5rem" }}>
                    <Flex gap="xs" align="center">
                        <MRT_GLOBAL_FILTER_TEXT_INPUT table={table} />
                        <MRT_SHOW_HIDE_COLUMNS_BUTTON
                            onClick={() => {
                                trackUserEventWithCurrentEvent({
                                    name: "show_hide_columns_clicked",
                                });
                            }}
                            table={table}
                        />
                        <div className={classes.DownloadButtons}>
                            <div
                                data-for={"DownloadData"}
                                data-tip={
                                    disableDownloads
                                        ? "Access Restricted: You do not have permission to download Insights."
                                        : "Download Current View: Applies to data shown, including filters."
                                }
                            >
                                <Button
                                    size={{ width: "18rem", height: "3rem" }}
                                    onClick={exportTableData}
                                    disabled={disableDownloads || isDownloading}
                                >
                                    {permissionLoading || isDownloading ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            <Icon
                                                path={mdiDownload}
                                                size={1.2}
                                            />
                                            Download Data
                                        </>
                                    )}
                                </Button>
                                <ReactTooltip
                                    id={"DownloadData"}
                                    place={"top"}
                                    effect={"float"}
                                />
                            </div>

                            {allowShowOnMap && (
                                <Button
                                    size={{ width: "18rem", height: "3rem" }}
                                    onClick={async () => {
                                        trackUserEventWithCurrentEvent({
                                            name: "show_on_map_clicked",
                                            payload: {
                                                source,
                                                type,
                                            },
                                        });

                                        const bboxRes = (
                                            await axios.get(
                                                `${
                                                    import.meta.env
                                                        .VITE_GIM_ROOT
                                                }/bbox/${selectedRevisionId}?cursor=${insightsCursor}`,
                                            )
                                        ).data;

                                        const { bbox } = parseWKTBBox(
                                            bboxRes.geometry,
                                        );

                                        dispatch(setShowClusters(false));

                                        dispatch(
                                            setDashboardView({
                                                view: "report",
                                            }),
                                        );

                                        window.history.replaceState(
                                            null,
                                            "",
                                            window.location.href.replace(
                                                "page=insights",
                                                "page=report",
                                            ),
                                        );
                                        mapRef?.current?.fitBounds(bbox, {
                                            padding: 50,
                                        });
                                    }}
                                    // disabled={
                                    //     !table.getFilteredRowModel().rows.length
                                    // }
                                >
                                    Show on map
                                </Button>
                            )}
                        </div>
                    </Flex>
                    <Flex align={"center"} gap={"sm"}>
                        {queryParamsKey?.length > 0 && (
                            <div
                                data-tip={"Reset all filters"}
                                data-for={"ResetAllFilters"}
                            >
                                <Button
                                    size={{ width: "3rem", height: "3rem" }}
                                    onClick={() => {
                                        state.resetColumnFilters();
                                        dispatch(
                                            SetAssessmentFilters({
                                                assessmentType: "claims",
                                                assessmentFilter: null,
                                            }),
                                        );
                                        dispatch(
                                            SetAssessmentFilters({
                                                assessmentType: "exposure",
                                                assessmentFilter: null,
                                            }),
                                        );
                                    }}
                                >
                                    <Icon path={mdiFilterRemove} size={1.5} />
                                </Button>
                                <ReactTooltip
                                    id={"ResetAllFilters"}
                                    place={"top"}
                                    effect={"solid"}
                                />
                            </div>
                        )}
                        {insightsData?.description.as_at_date &&
                            `As At Date: ${insightsData?.description?.as_at_date} | `}
                        Exposure Layer Version:{" "}
                        {insightsData?.description?.exposure_revision} | Claims
                        Layer Version:{" "}
                        {insightsData?.description?.claims_revision}
                    </Flex>
                </Flex>
            </Box>
        );
    };

    const exportTableData = async () => {
        setIsDownloading(true);
        const segments = exportSuffix.split("_");
        segments.splice(segments.length - 3, 1);

        trackUserEventWithCurrentEvent({
            name: "insights_download_data_clicked",
            payload: {
                source,
                type,
                portfolio: new URLSearchParams(window.location.search).get("portfolio")!,
                peril: peril as PerilType,
            },
        });

        try {
            const hasAnyGlobalFilterOrColumnFilters = (
                Boolean(query?.global_filter) ||
                query?.columnFilters?.length! > 0
            )
            const cursor = (insightsCursor && hasAnyGlobalFilterOrColumnFilters) ? `?cursor=${insightsCursor}` : "";
            const response = await axios({
                url: `${import.meta.env.VITE_GIM_ROOT}/table/${selectedRevisionId}/download${cursor}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                setIsDownloading(false);
                return response
            })

            // Create download link and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${exportSuffix}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
            // Handle error appropriately
            setIsDownloading(false);
        }
};
    const table = useMantineReactTable({
        columns: columnHeaders.current,
        data: flatData,
        enableDensityToggle: false,
        ...eventHandlers,
        manualPagination: true,
        enableColumnDragging: false,
        enableClickToCopy: true,
        manualFiltering: true,
        enableHiding: true,
        enableColumnActions: true,
        enableColumnVirtualization: true,
        enableRowVirtualization: true,
        // TODO: need to hardcode column max widths
        rowVirtualizerProps: {
            //adjust the number of rows that are rendered to the top and bottom of the visible area of the table
            overscan: 20,
            //if your rows are taller, try tweaking this value to make scrollbar size more accurate
            estimateSize: () => 15,
        },
        columnVirtualizerProps: {
            //adjust the number of columns that are rendered to the left and right of the visible area of the table
            overscan: 8,
            //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
            // estimateSize: () => 140,
        },
        columnFilterModeOptions: allFilterModes,
        enableColumnFilterModes: true,
        enableColumnFilters: true,
        enableSorting: true,
        enableTopToolbar: true,
        enablePagination: false,
        enablePinning: true,
        enableFilters: true,
        manualSorting: true,
        rowCount: data?.pages[0].total ?? 0,
        enableFullScreenToggle: false,
        enableColumnResizing: true,
        // enableStickyHeader: true,
        mantineSkeletonProps: ({ cell, column, row, table }) => ({
            sx: {
                minHeight: "1.5rem",
            },
        }),
        initialState: {
            pagination: {
                pageSize: 25,
                pageIndex: 0,
            },
            showSkeletons,
        },
        state: {
            ...state,
            showGlobalFilter: true,
            showSkeletons,
            showProgressBars: isFetchingNextPage,
        },
        mantineFilterTextInputProps: {
            styles: {
                input: {
                    backgroundColor: "var(--secondary-color)",
                    "&::placeholder": {
                        color: "var(--text-color-lo-cont)",
                    },
                },
            },
        },
        mantineTableContainerProps: {
            ref: tableContainerRef,
            sx: {
                maxHeight:
                    tableContainerMaxHeight ||
                    "calc(100vh - 150px - 0.0625rem - 1.5rem - 30px - 70px - 40px)" ||
                    "400px",
            },
            onScroll: (event: UIEvent) =>
                fetchMoreOnBottomReached(event.target as HTMLDivElement),
        },
        renderBottomToolbar: () => {
            const bottomStyle: CSSProperties = {
                position: "absolute",
                height: "4.4rem",
                bottom: "-3.3rem",
                zIndex: 4,
                backgroundColor: "var(--secondary-color)",
                borderBottom: "1px solid var(--border-color)",
                width: "100vw",
            };
            if (isFetchingNextPage) {
                return (
                    <Flex
                        justify="center"
                        align="center"
                        gap="md"
                        p="md"
                        style={bottomStyle}
                    >
                        Loading new page...
                        <Loader variant="dots" />
                    </Flex>
                );
            }
            return (
                <Flex
                    justify="center"
                    align="center"
                    gap="md"
                    p="md"
                    style={bottomStyle}
                >
                    {hasNextPage ? (
                        <Text size="sm" c="var(--text-color)">
                            {`Scroll for more: showing ${data?.pages
                                ?.flatMap((page) => page.data.items.length)
                                .reduce((a, b) => a + b, 0)} of ${data?.pages[0]
                                .total} results`}
                        </Text>
                    ) : (
                        <Text size="sm" c="var(--text-color)">
                            No more data to load
                        </Text>
                    )}
                </Flex>
            );
        },
        mantineTableProps: {
            verticalSpacing: "0.2rem",
            height: totalHeight,
            display: "block",
        },
        mantineSearchTextInputProps: {
            sx: {
                marginRight: "1rem",
            },
            styles: {
                input: {
                    backgroundColor: "var(--secondary-color-lo-cont)",
                    "&::placeholder": {
                        color: "var(--text-color-lo-cont)",
                    },
                },
                wrapper: {
                    borderRadius: "var(--border-radius-md)",
                    backgroundColor: "var(--secondary-color-lo-cont)",
                },
            },
        },
        icons: {
            IconDotsVertical: () => (
                <Icon
                    path={mdiDotsVertical}
                    style={{
                        transform: "scale(1.25)",
                    }}
                />
            ),
            IconSortDescending: () => (
                <Icon
                    path={mdiMenuDown}
                    style={{
                        transform: "scale(1.75)",
                    }}
                />
            ),
            IconSortAscending: () => (
                <Icon
                    path={mdiMenuUp}
                    style={{
                        transform: "scale(1.75)",
                    }}
                />
            ),
            IconArrowsSort: () => (
                <Icon
                    path={mdiMenuSwap}
                    style={{
                        transform: "scale(1.75)",
                    }}
                />
            ),
            IconSearch: () => (
                <IconSearch
                    style={{
                        transform: "scale(0.75)",
                    }}
                />
            ),
            IconFilter: () => (
                <Icon
                    path={mdiFilter}
                    style={{
                        transform: "scale(1.5)",
                    }}
                />
            ),
        },
        mantineTableBodyRowProps: {
            sx: {
                ":hover": {
                    opacity: 0.75,
                },
            },
        },
        mantineCopyButtonProps: {
            sx: {
                ":hover": {
                    backgroundColor: "var(--highlight-color-50)",
                },
                ":active": {
                    backgroundColor: "var(--highlight-color-75)",
                },
            },
        },
        mantinePaginationProps: {
            showRowsPerPage: false,
        },
        mantineTableBodyCellProps: {
            sx: {
                textWrap: "nowrap",
                border: "none",
                backgroundColor: "var(--secondary-color-lo-cont)",
            },
            style: {
                maxWidth: "max-content",
            },
        },
        mantineTopToolbarProps: {
            sx: {
                borderBottom: `0.1rem solid ${getCssVar("--border-color")}`,
            },
        },
        mantinePaperProps: {
            sx: {
                border: "none",
            },
        },
        renderTopToolbar: ({ table }) => renderTableActionBar(table),
    });

    return (
        <div className={classes.InsightsTable}>
            <MantineReactTable
                key={
                    assessmentFilters.claims.toString() +
                    assessmentFilters.exposure.toString()
                }
                table={table}
            />
        </div>
    );
};
