import React from "react";
import startCase from "lodash/startCase";
import {
    MRT_ColumnDef,
    MRT_Header,
    type MRT_Cell,
    MRT_Row,
} from "mantine-react-table";
import { formatValue } from "store/insights/utils";
import ReactTooltip from "react-tooltip";
import classes from "./MantineTable.module.css";

export const allFilterModes = [
    "between",
    "betweenInclusive",
    "contains",
    "empty",
    "endsWith",
    "equals",
    "greaterThan",
    "greaterThanOrEqualTo",
    "lessThan",
    "lessThanOrEqualTo",
    "notEquals",
    "startsWith",
];
export const layerAssessmentColumnNames = [
    "Exposure Layer Assessment",
    "Claims Layer Assessment",
];
const layerAssessmentColumnNamesToCustomSort = [
    ...layerAssessmentColumnNames,
    "Hail Intensity",
]; //Needed to handle old hail data

const customSortingFunction = (
    rowA: MRT_Row,
    rowB: MRT_Row,
    id: string,
    damageClassifications: string[],
) => {
    return (
        damageClassifications.indexOf(rowA.getValue(id)) -
        damageClassifications.indexOf(rowB.getValue(id))
    );
};

export const extractColumnNames = (
    data: Record<string, string | number>[],
    columns: string[],
    format: boolean = false,
    exposureDamageClassifications: string[] = [],
    claimsDamageClassifications: string[] = [],
): MRT_ColumnDef<any>[] => {
    if (columns.length !== 0) {
        return Array.from(columns)
            .map((key) => {
                return {
                    id: key,
                    accessorKey: key,
                    header: startCase(key),
                    sortingFn: layerAssessmentColumnNamesToCustomSort.includes(
                        key,
                    )
                        ? (rowA: any, rowB: any, id: string) => {
                              const damageClassifications = key
                                  .toLowerCase()
                                  .includes("claims")
                                  ? claimsDamageClassifications
                                  : exposureDamageClassifications;
                              return customSortingFunction(
                                  rowA,
                                  rowB,
                                  id,
                                  damageClassifications,
                              );
                          }
                        : "alphanumeric",
                    columnFilterModeOptions:
                        layerAssessmentColumnNames.includes(key)
                            ? ["arrIncludesSome"]
                            : allFilterModes,
                    // @ts-ignore: MRT has no proper type for renderedCellValue
                    Cell: ({ renderedCellValue }: { cell: MRT_Cell }) => {
                        return format && renderedCellValue
                            ? formatValue(renderedCellValue, key)
                            : renderedCellValue;
                    },
                    accessorFn: (row: Record<string, string | number>) =>
                        row[key!] ?? "",
                    Header: ({ column }: MRT_Header) => (
                        <span
                            className={classes.ColumnHeader}
                            data-tip={
                                layerAssessmentColumnNames.includes(
                                    column.columnDef.header,
                                )
                                    ? `You can filter by ${column.columnDef.header} using the interactive bar charts above!`
                                    : ""
                            }
                            data-for={column.id}
                            onMouseEnter={() => {
                                if (
                                    layerAssessmentColumnNames.includes(
                                        column.columnDef.header,
                                    )
                                ) {
                                    const BarCharts =
                                        document.getElementById("barCharts");
                                    BarCharts!.style.cssText = `
                                        border: 0.2rem solid var(--highlight-color); 
                                        border-radius: var(--border-radius-sm);
                                    `;
                                }
                            }}
                            onMouseLeave={() => {
                                if (
                                    layerAssessmentColumnNames.includes(
                                        column.columnDef.header,
                                    )
                                ) {
                                    const BarCharts =
                                        document.getElementById("barCharts");
                                    BarCharts!.style.cssText = `
                                        border: 0.2rem solid transparent; 
                                        border-radius: var(--border-radius-sm);
                                    `;
                                }
                            }}
                        >
                            {column.columnDef.header}
                            <ReactTooltip
                                id={column.id}
                                place={"top"}
                                effect={"solid"}
                                className={classes.TextWrapTooltip}
                            />
                        </span>
                    ),
                };
            })
            .filter(
                (column) =>
                    !["feature_id", "MIS_ContractID"].includes(
                        column.accessorKey,
                    ),
            );
    }
    return Object.keys(data[0]).map((key) => ({
        id: key,
        accessorKey: key,
        header: startCase(key),
    }));
};
