import {
    InfiniteData,
    useInfiniteQuery,
    UseInfiniteQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { setInsightsCursor } from "store/insights/insightsActions";

export interface QueryParams {
    cursor?: string;
    global_filter?: string;
    sorting?: { id: string; desc: boolean }[];
    columnFilters?: { column: string; operator: string; value: any }[];
    limit: number;
}

export interface PaginatedResponse<T> {
    data: T;
    cursor: string | null;
    total: number;
    has_more: boolean;
}

export interface InsightsResponse {
    items: any[];
    stats: any[];
}

async function fetchInsightsDetails<T>(
    revisionId: string,
    query: QueryParams,
    token: string,
    pageParam: string,
): Promise<PaginatedResponse<T>> {
    // Transform column filters to match API expectations
    const transformedFilters = query.columnFilters
        ?.map((filter) => ({
            column: filter.column,
            operator: filter.operator,
            // If value is an array with empty string, send null or undefined instead
            value:
                Array.isArray(filter.value) && filter.value[0] === ""
                    ? null
                    : filter.value,
        }))
        .filter((f) => f?.value !== null && f?.value !== undefined);

    let payload;
    // TODO: Clean me
    if (pageParam) {
        payload = {
            cursor: pageParam,
        };
    } else {
        payload = {
            global_filter: query.global_filter,
            sorting:
                query.sorting?.map((sort) => ({
                    column: sort.id,
                    order: sort.desc ? "DESC" : "ASC",
                })) || [],
            column_filters: transformedFilters || [],
        };
    }
    const { data } = await axios.post<PaginatedResponse<T>>(
        `${import.meta.env.VITE_GIM_ROOT}/table/${revisionId}/`,
        {
            ...payload,
            limit: query.limit,
        },
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    );
    return data;
}

export function useInsightsDetailsQuery<T = any>(
    revisionId: string,
    query: QueryParams,
    queryParamsKey: string[],
    options?: { enabled?: boolean; staleTime?: number },
): UseInfiniteQueryResult<InfiniteData<PaginatedResponse<T>>, Error> {
    const { oktaAuth } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    return useInfiniteQuery<PaginatedResponse<T>>(
        {
            queryKey: ["insightsDetails", revisionId, ...queryParamsKey],
            queryFn: ({ pageParam }) =>
                fetchInsightsDetails<T>(
                    revisionId,
                    query,
                    token!,
                    pageParam as string,
                ),
            initialPageParam: "",
            refetchOnWindowFocus: true,
            staleTime: options?.staleTime || Infinity,
            gcTime: Infinity,
            getNextPageParam: (lastPage: PaginatedResponse<T>) => {
                if (lastPage.cursor) {
                    dispatch(setInsightsCursor(lastPage.cursor));
                }
                return lastPage.cursor;
            },
            enabled: options?.enabled,
        }!,
    );
}
