import { EventSchema } from "crud/eventsCRUD";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { InsightsState } from "store/insights/insightsTypes";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { InsightsTable } from "./InsightsTable/InsightsTable";
import { buildExportName } from "./InsightsTable/utils";
import { MRT_Row } from "mantine-react-table";
import { toggleModal } from "store/system/systemActions";
import { RootState } from "store/store";
import { filterGeojsonByIndex, geoJSONFromPapaparse } from "utils/Geojson";
import { useCurrentEvent } from "hooks/useCurrentEvent";

type Props = {
    loadingInsights: boolean;
    insightsData: InsightsState["insightsData"];
    event: EventSchema;
    viewportHeightPct?: number;
    tableContainerMaxHeight?: string;
};

export const LocationInsightsWrapper = ({
    loadingInsights,
    insightsData,
    viewportHeightPct,
    tableContainerMaxHeight,
}: Props) => {
    const locationData = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").locationData,
    );

    const dispatch = useDispatch();

    let currentEvent = useCurrentEvent();

    const beforeShowOnMap = (rows: MRT_Row[]) => {
        let filteredIndices = rows.map((row) => {
            return row.index;
        });

        dispatch(toggleModal(false));
        const filteredGeojson = filterGeojsonByIndex(
            geoJSONFromPapaparse(locationData!),
            filteredIndices,
        );
        // dispatch(setInsightsGeojson(filteredGeojson));

        return filteredGeojson;
    };
    const assessmentFilters = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").assessmentFilters,
    );
    return (
        <InsightsTable
            allowShowOnMap={true}
            loading={false}
            columns={locationData?.meta?.fields!}
            key={
                insightsData?.description?.claims_revision! +
                insightsData?.description?.exposure_revision! +
                insightsData?.description?.as_at_date! +
                assessmentFilters.claims +
                assessmentFilters.exposure
            }
            exportSuffix={`${buildExportName(
                insightsData?.description || {},
                currentEvent?.name || "",
            )}_Location`}
            viewportHeightPct={viewportHeightPct}
            tableContainerMaxHeight={tableContainerMaxHeight}
            beforeShowOnMap={beforeShowOnMap}
            formatData
        />
    );
};
