import React, { useEffect, useRef, useState } from "react";
import { MapRef } from "react-map-gl";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useUserMeAccessQuery } from "crud/me_access";
import { ReportTimelineSchemaBase } from "store/report/reportTypes";
import { BasemapType, LayerInfo } from "store/report/reportTypes";
import Timeline from "../../../Timeline/Timeline";
import classes from "./MapContainer.module.css";
import MapMenu from "../../MapMenu/MapMenu";

import MapLayers, { MapSearchMarker } from "../MapLayers/DataLayers";
import { InsightsLayers } from "../MapLayers/InsightsLayers";
import MapInteractionContainer, {
    InteractionModeMapProps,
} from "../MapInteractions/InteractionModeContainer/InteractionModeContainer";
import { setBasemap } from "store/report/reportActions";
import { setRef } from "store/ref/refActions";
import DragAndDrop from "components/Pages/Report/DragAndDrop/DragAndDrop";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import {
    removeInsightsData,
    setContractIdFilter,
    setSelectedPortfolio,
} from "../../../../../../../store/insights/insightsActions";
import { RangeSelect } from "components/_Library/RangeSelect/RangeSelect";
import { UseQueryResult } from "@tanstack/react-query";
import { ReportMapSchema } from "crud/reportMapsCRUD";
import { Button, LoadingOverlay } from "@mantine/core";
import MegaMap from "../MegaMap/MegaMap";
import { constructWKTBBox, parseWKTBBox } from "utils/Coordinates";
import { IconCopy } from "@tabler/icons-react";
import { setAlert } from "store/system/systemActions";
import { RootState } from "store/store";
import { useParams } from "react-router-dom";

interface MapContainerProps {
    toggleDownloadModal: () => void;
    mapData: ReportMapSchema | undefined;
    isMapLoading: boolean;
    isLayersConfigLoading: boolean;
    timelineQuery: UseQueryResult<ReportTimelineSchemaBase, Error>;
    hasReportId: boolean;
}

interface PopupInfo {
    latitude: number;
    longitude: number;
}

interface ReportParams {
    latitude: string | undefined;
    longitude: string | undefined;
    zoom: string | undefined;
}

export interface LayerSelectPopupInfo extends PopupInfo {
    layers: LayerInfo[];
}

export interface MapContextMenuInfo extends PopupInfo {
    display: boolean;
    mapRef: React.RefObject<MapRef>;
}

export interface MeasurePopupInfo extends PopupInfo {
    measurement: number | null;
}

const MapContainer: React.FC<MapContainerProps> = ({
    toggleDownloadModal,
    mapData,
    isMapLoading,
    isLayersConfigLoading,
    timelineQuery,
    hasReportId,
}) => {
    // Hooks
    const dispatch = useDispatch();
    const { data: userMeAccessData } = useUserMeAccessQuery();

    const { latitude, longitude, zoom } = useParams<ReportParams>();

    // Refs
    const leftMapRef = useRef<MapRef>(null);
    const rightMapRef = useRef<MapRef>(null);
    const mapContainerRef = useRef<HTMLDivElement>(null);

    // State
    const [currentWidth, setCurrentWidth] = useState(0);
    const [dragAndDropActive, setDragAndDropActive] = useState(false);

    // Selectors
    const layerFilters = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").layerFilters,
    );
    const layersConfig = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").layersConfig,
    );
    const mapboxToken = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").mapboxToken,
    );
    const interactiveLayerIds = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").interactiveLayerIds,
    );
    const basemaps = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "report").basemaps,
    );
    const basemapOptions = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").basemapOptions,
    );
    const mapMarkerLocation = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").mapMarkerLocation,
    );
    const locationLabels = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").locationLabels,
    );
    const policyData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").policyData,
    );
    const currentTimelineDate = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").currentTimelineDate,
    );
    const isPreview = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "report").isPreview,
    );

    // Effects
    useEffect(() => {
        const setWidth = () => {
            if (mapContainerRef.current) {
                setCurrentWidth(mapContainerRef.current.offsetWidth || 800);
            }
        };

        setWidth();
        window.addEventListener("resize", setWidth);

        dispatch(setRef({ refName: "mapRef", ref: rightMapRef }));
        dispatch(removeInsightsData());

        const currentTheme = document
            .getElementsByTagName("html")[0]
            .getAttribute("data-theme");

        dispatch(
            setBasemap({
                mapIndex: 1,
                basemap: currentTheme as BasemapType,
            }),
        );

        return () => {
            dispatch(setSelectedPortfolio({ selectedPortfolio: null }));
            window.removeEventListener("resize", setWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (latitude && longitude && zoom) {
            rightMapRef.current?.flyTo({
                center: [parseFloat(longitude), parseFloat(latitude)],
                zoom: parseFloat(zoom),
            });
        } else if (mapData && (isMapLoading || mapData)) {
            if (mapData.bbox) {
                const { north, south, east, west } = parseWKTBBox(mapData.bbox);

                rightMapRef.current?.fitBounds([west, south, east, north]);
            } else if (
                mapData.initial_lat &&
                mapData.initial_long &&
                mapData.initial_zoom
            ) {
                rightMapRef.current?.flyTo({
                    center: [mapData.initial_long, mapData.initial_lat],
                    zoom: mapData.initial_zoom,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapData, isMapLoading, dispatch, rightMapRef.current]);

    const getMapBboxCopyText = (): string => {
        if (!rightMapRef.current) return "";
        const bounds = rightMapRef.current.getMap()?.getBounds();
        if (!bounds) return "";

        return constructWKTBBox(
            bounds.getNorth().toFixed(4),
            bounds.getEast().toFixed(4),
            bounds.getSouth().toFixed(4),
            bounds.getWest().toFixed(4),
        );
    };

    const renderMap = () => {
        let layers: (React.JSX.Element | null)[] = [];

        if (layersConfig) {
            layers = MapLayers(
                layersConfig,
                locationLabels,
                layerFilters,
                currentTimelineDate,
            );
        }

        // Collate all props for the map component
        let props = {
            mapType: layersConfig.mapType || "single",
            basemaps: basemaps.map((elem) => basemapOptions[elem]),
            interactiveLayerIds: interactiveLayerIds,
            mapboxToken: mapboxToken,
            leftMapRef: leftMapRef,
            rightMapRef: rightMapRef,
            layersConfig: layersConfig,
            layers: layers,
            insightsLayers: !isLayersConfigLoading ? <InsightsLayers /> : null,
            mapSearchMarker: MapSearchMarker({
                lnglat: mapMarkerLocation!,
            }),
        };

        const mapRenderFunction = ({
            interactions,
            additionalInteractiveLayerIds,
            layers,
            popup,
            contextMenu,
            controls,
        }: InteractionModeMapProps) => {
            return (
                <MegaMap
                    key={"map"}
                    {...interactions}
                    {...props}
                    additionalInteractiveLayerIds={
                        additionalInteractiveLayerIds
                    }
                    contextMenu={contextMenu}
                    popup={popup}
                    controls={controls}
                    additionalLayers={layers}
                />
            );
        };

        const all_estimated_exposure: number[] | undefined = policyData?.data
            ? policyData?.data.map((data) => data.estimated_exposure)
            : [];

        return (
            <>
                <div
                    className={classes.MapComponentContainer}
                    id={"tourid_MapComponentContainer"}
                >
                    <div
                        ref={mapContainerRef}
                        className={cx(classes.HorizontalContainer, {
                            [classes.CaptureCoords]: false,
                        })}
                    >
                        {userMeAccessData &&
                            userMeAccessData.has_policy_access &&
                            policyData?.data && (
                                <RangeSelect
                                    minValue={Math.min(
                                        ...all_estimated_exposure,
                                    )}
                                    maxValue={Math.max(
                                        ...all_estimated_exposure,
                                    )}
                                    title={"Estimated Exposure"}
                                    format
                                    prefix={"$"}
                                    onSubmit={(value: {
                                        min: number;
                                        max: number;
                                    }) => {
                                        let contractIds: number[] | null =
                                            policyData!.data
                                                .filter(
                                                    (data) =>
                                                        data.estimated_exposure >=
                                                            value.min &&
                                                        data.estimated_exposure <=
                                                            value.max,
                                                )
                                                .map(
                                                    (data) =>
                                                        data.MIS_ContractID,
                                                );

                                        if (contractIds && !contractIds.length)
                                            contractIds = null;

                                        dispatch(
                                            setContractIdFilter({
                                                contractIds: contractIds,
                                            }),
                                        );
                                    }}
                                />
                            )}

                        <div className={classes.MapContainer}>
                            <LoadingOverlay
                                visible={isMapLoading || !hasReportId}
                            />

                            <MapInteractionContainer
                                mapContainerWidth={currentWidth}
                                mapRenderFunction={mapRenderFunction}
                            />
                        </div>
                        <MapMenu
                            isLayersConfigLoading={isLayersConfigLoading}
                            setDragAndDropState={setDragAndDropActive}
                            toggleDownloadModal={toggleDownloadModal}
                        />
                    </div>
                    {timelineQuery.data && (
                        <Timeline
                            key={mapData?.id}
                            reportTimeline={timelineQuery.data}
                        />
                    )}
                </div>
                {dragAndDropActive && (
                    <DragAndDrop setDragAndDropState={setDragAndDropActive} />
                )}
                {isPreview && (
                    <div
                        style={{
                            position: "absolute",
                            top: "1rem",
                            left: "1rem",
                        }}
                    >
                        <Button
                            variant="confirm"
                            onClick={() => {
                                const bbox = getMapBboxCopyText();
                                navigator.clipboard.writeText(bbox);
                                dispatch(
                                    setAlert({
                                        type: "Default",
                                        message: "Bounding Box to clipboard",
                                    }),
                                );
                            }}
                        >
                            <IconCopy size={16} />
                            <p>Copy Bounding Box</p>
                        </Button>
                    </div>
                )}
            </>
        );
    };

    return <div className={classes.MapContainer}>{renderMap()}</div>;
};

export default MapContainer;
